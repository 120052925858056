var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "allInformationConfAdd commonF" }, [
    _c(
      "div",
      { staticStyle: { width: "800px", display: "inline-block" } },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "demo-form-inline",
            attrs: {
              inline: false,
              "label-position": "right",
              model: _vm.formInline,
              "label-width": "100px",
              rules: _vm.rules,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "消息标题", prop: "msgTitle" } },
              [
                _c("el-input", {
                  attrs: { maxlength: "20" },
                  model: {
                    value: _vm.formInline.msgTitle,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formInline,
                        "msgTitle",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formInline.msgTitle",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticStyle: { "text-align": "left" },
                attrs: { label: "接收人群", prop: "recipients" },
              },
              [
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "搜索账号名称", maxlength: "20" },
                  on: { input: _vm.vagueNameChange },
                  model: {
                    value: _vm.vagueName,
                    callback: function ($$v) {
                      _vm.vagueName = $$v
                    },
                    expression: "vagueName",
                  },
                }),
                _c("el-transfer", {
                  attrs: {
                    props: {
                      key: "bossManagerId",
                      label: "bossManagerName",
                    },
                    "right-default-checked": _vm.rightDataList,
                    "filter-placeholder": "请输入车场名称",
                    data: _vm.bossManangerData,
                    titles: ["未选择", "已选择"],
                  },
                  on: { change: _vm.rightDataChange },
                  model: {
                    value: _vm.formInline.recipients,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "recipients", $$v)
                    },
                    expression: "formInline.recipients",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "消息内容", prop: "msgContent" } },
              [_c("div", { staticClass: "editor", attrs: { id: "editor" } })]
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function ($event) {
                        _vm.page = 1
                        _vm.submit()
                      },
                    },
                  },
                  [_vm._v("提交")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { loading: _vm.loading },
                    on: {
                      click: function ($event) {
                        return _vm.$router.go("-1")
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }