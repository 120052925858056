<template>
  <div class="allInformationConfAdd commonF">
    <div style="width: 800px; display: inline-block">
      <el-form :inline="false" label-position="right" :model="formInline" class="demo-form-inline" label-width="100px" :rules="rules" ref="form">
        <el-form-item label="消息标题" prop="msgTitle">
          <el-input v-model.trim="formInline.msgTitle" maxlength="20"></el-input>
        </el-form-item>
        <!-- <el-form-item label="接收方式" style="text-align: left" prop="sendType">
          <el-radio v-model="formInline.sendType" label="0">站内消息</el-radio>
          <el-radio v-model="formInline.sendType" label="1">邮件</el-radio>
        </el-form-item> -->
        <el-form-item label="接收人群" style="text-align: left" prop="recipients">
          <el-input placeholder="搜索账号名称" v-model="vagueName" maxlength="20" style="width: 200px" @input="vagueNameChange"></el-input>
          <el-transfer
            :props="{
              key: 'bossManagerId',
              label: 'bossManagerName'
            }"
            @change="rightDataChange"
            :right-default-checked="rightDataList"
            filter-placeholder="请输入车场名称"
            v-model="formInline.recipients"
            :data="bossManangerData"
            :titles="['未选择', '已选择']">
          </el-transfer>
        </el-form-item>
        <el-form-item label="消息内容" prop="msgContent">
          <div id="editor" class="editor"></div>
        </el-form-item>
        <!-- <el-form-item label="发送方式" prop="msgContent">
          <el-radio v-model="radio" label="1">立即</el-radio>
          <el-radio v-model="radio" label="2">预约</el-radio>
          <el-date-picker
            v-model="value1"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="page=1;submit()"  :loading="loading">提交</el-button>
          <el-button @click="$router.go('-1')"  :loading="loading">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import E from 'wangeditor'
export default {
  data () {
    let checkMessage = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback(new Error('请输入消息内容'));
      } else if (len > 207) {
        callback(new Error('长度不能超过200 个字符'))
      } else {
        callback();
      }
    };
    return {
      rightDataList: [],
      rules: {
        msgTitle: [{
          required: true,
          message: '请输入消息标题',
          trigger: 'blur'
        }],
        recipients: [{
          required: true,
          message: '请输入接收人群',
          trigger: 'blur'
        }],
        msgContent: [{
          required: true,
          trigger: 'blur',
          validator: checkMessage
        }]
      },
      vagueName: '',
      formInline: {
        sendType: '0',
        msgTitle: '',
        sendState: '',
        recipients: [],
        msgContent: ''
      },
      loading: false,
      bossManangerData: []
    }
  },
  methods: {
    rightDataChange(value, direction, movedKeys) {
      if (this.bossManangerData && value) {
        let defaultParkData = []
        value.forEach((bossManagerId) => {
          this.bossManangerData.forEach((bossManangerData) => {
            if (bossManagerId === bossManangerData.bossManagerId) {
              defaultParkData.push(bossManangerData)
            }
          })
        })
        this.defaultParkData = defaultParkData
      }
    },
    // 将现有人群添加进新获取的人群数据中去，防止出现显示BUG
    vagueNameChange() {
      this.bossManangerData = []
      this.$axios.get('/acb/2.0/bossManager/list', {
        data: {
          page: 1,
          size: 1000,
          bossManagerName: this.vagueName
        }
      })
        .then((res) => {
          this.bossManangerData = res.value.list
          if (this.defaultParkData) {
            this.defaultParkData.forEach((e) => {
              let flag = false;
              this.bossManangerData.forEach((i) => {
                if (e.bossManagerId === i.bossManagerId) {
                  flag = true;
                }
              })
              if (!flag) {
                this.bossManangerData.push(e)
              }
            })
          }
        })
    },
    init() {
      let editor = new E('#editor');
      editor.config.focus = false;
      editor.config.menus = [
        'head',
        'list',  // 列表
        'justify',  // 对齐方式
        'bold',
        'fontSize',  // 字号
        'italic',
        'underline',
        'image',  // 插入图片
        'foreColor',  // 文字颜色
        'undo',  // 撤销
        'redo'
      ]
      editor.config.onchange = (html) => {
        this.formInline.msgContent = html;
      };
      editor.config.onblur = (html) => {
        this.formInline.msgContent = html;
        this.$refs.form.validateField("msgContent");
      };
      editor.create()
    },
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$axios.post(`/acb/2.0/sysmgMsgConf/add`, {
            data: {
              ...this.formInline,
              recipients: this.formInline.recipients.join(',')
            }
          })
            .then((res) => {
              this.loading = false;
              if (res.state == 0) {
                this.$router.go('-1')
              } else {
                this.$alert(res.desc, '提示')
              }
            }).catch(() => {
              this.loading = false;
            })
        } else {
          return false;
        }
      });
    }
  },
  mounted () {
    this.init()
    this.vagueNameChange()
  }
}
</script>
<style lang="stylus" scoped>
  >>>.el-message-box__wrapper {
    z-index: 9999!important;
  }
  .allInformationConfAdd {
    margin: 0 auto;
    text-align:center
  }
  >>>.el-checkbox {
    display block;
  }
  >>>.w-e-text {
    text-align: left;
  }
</style>
